<template>
  <div id="">         
    
  <section id="dashboard" class="mt-4">

    <div class="container">

        <div class="row mt-5">

          <SideNav v-bind:selected='3'/>

            <div class="col-12">
                <form id="payment_method" class="mt-2">
                  <div class="mb-2"></div>

                    <div class="row mb-2">
                        <div class="col-6">
                            <input v-model="name" id="ticket_price" type="text" name="winner_name" placeholder="Winner Name">
                        </div>
                        <div class="col-6">
                            <input v-model="ticket" id="ticket_price" type="text" name="winner_ticket" placeholder="Winning Ticket">
                        </div>
                        <div class="col-12">
                            <input v-model="link" id="ticket_price" type="text" name="link" placeholder="Live Draw Link">
                        </div>
                    </div>

                    <textarea v-model="description" id="description" name="description" placeholder="Description" cols="30" rows="5">
                    </textarea>

                    <div class="row">
                        <div class="col">
                            <input v-on:change="onFileChange" type="file" id="img" name="img" accept="image/*">
                        </div>
                        <div class="col-6">
                            <input v-model="date" type="date" id="start_date" name="start_date">
                        </div>
                    </div>

                    <button v-on:click="addWinner" type="button" class="btn btn-custom mt-3 w-25">Add Winner</button> 

                </form>
            </div>
        </div>
    </div>
  </section>

  <Footer/>
  </div>
</template>



<script>
import Api from '../../scripts/api'

import SideNav from '../../components/admin/SideNavigator'
import Footer from '../../components/global/Footer'

export default {
    props: {
      account:Object
    },
    components: {
        Footer,
        SideNav
    },
    data() {
      return {
        name: undefined,
        ticket: undefined,
        description: undefined,
        file: undefined,
        date: undefined,
        link: undefined
      }
    },
    async created() {
      var result = await Api.admin.isAdmin();
      if(result.status != 200) {
        this.$router.push('/');
      }
    },
    methods: {
      onFileChange: function(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.file = files[0];
      },
      addWinner: async function() {
        if(!this.name) return this.$toast.warning('Name cannot be empty.');
        if(!this.ticket) return this.$toast.warning('Ticket cannot be empty.');
        if(!this.description) return this.$toast.warning('Description cannot be empty.');
        if(!this.date) return this.$toast.warning('Please set a date.');
        if(!this.file) return this.$toast.warning('Please set an image.');
        if(!this.link) return this.$toast.warning('Please enter the live draw link');
        
        var result = await Api.admin.winnerAdd(this.name, this.ticket, this.description, this.file, this.date, this.link);
        if(result.status == 200) {
          var data = await result.json();
          this.$router.push(data.redirect);
          this.$toast.success('Added winner!');
        } else {
          this.$toast.warning('Error occurred: double check the entered data.');
        }
      }
    }
}
</script>
